import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [4,3,5];

export const dictionary = {
		"/(unprotected)/[[lang]]/about": [10,[2]],
		"/admin/ingredients": [~29,[4]],
		"/admin/items": [30,[4]],
		"/(unprotected)/[[lang]]/blog": [11,[2]],
		"/(unprotected)/[[lang]]/blog/are-cooking-sprays-healthy": [12,[2]],
		"/(unprotected)/[[lang]]/blog/cooking-vs-baking": [13,[2]],
		"/(unprotected)/[[lang]]/blog/cooking-without-salt": [14,[2]],
		"/(unprotected)/[[lang]]/blog/does-cooking-kill-salmonella": [15,[2]],
		"/(unprotected)/[[lang]]/blog/elevating-canned-foods": [16,[2]],
		"/(unprotected)/[[lang]]/blog/introducing": [17,[2]],
		"/(unprotected)/[[lang]]/blog/learn-to-cook": [18,[2]],
		"/(unprotected)/[[lang]]/blog/where-cooking-begins": [19,[2]],
		"/(unprotected)/[[lang]]/blog/why-cooking-is-important": [20,[2]],
		"/(unprotected)/[[lang]]/essentials": [21,[2]],
		"/(unprotected)/[[lang]]/features": [~22,[2]],
		"/(unprotected)/[[lang]]/for-creators": [23,[2]],
		"/(unprotected)/[[lang]]/(account)/login": [~7,[3]],
		"/(unprotected)/[[lang]]/(account)/logout": [~8,[3]],
		"/my/dashboard": [~31,[5]],
		"/my/fridge": [~32,[5]],
		"/my/groceries": [~33,[5]],
		"/my/pantry": [34,[5]],
		"/my/plans": [35,[5]],
		"/my/plans/[id]": [~36,[5]],
		"/my/recipes": [37,[5]],
		"/my/recipes/[id]": [~38,[5]],
		"/my/settings": [~39,[5]],
		"/my/settings/plan": [~40,[5]],
		"/(unprotected)/[[lang]]/pricing": [24,[2]],
		"/(unprotected)/[[lang]]/privacy": [25,[2]],
		"/(unprotected)/[[lang]]/recipes": [26,[2]],
		"/(unprotected)/[[lang]]/(account)/register": [~9,[3]],
		"/(unprotected)/[[lang]]/terms-of-service": [27,[2]],
		"/(unprotected)/[[lang]]/who-is-cooking": [28,[2]],
		"/(unprotected)/[[lang]]": [~6,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';